.rbc-calendar {
    width: 100%;
    min-height: 269px;
    border: 1px solid black;
    border-radius: 8px;
    overflow: hidden;
}

.rbc-calendar .rbc-row-bg,
.rbc-day-bg,
.rbc-off-range-bg,
.rbc-date-cell {
    height: 100%;
    padding-right: 0px;
}

.rbc-calendar .rbc-month-row {
    height: 100%;
    border-left: none;
    border-right: none;
}

.rbc-calendar .rbc-header {
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #ebf1f7;
}

.rbc-calendar .rbc-row-content {
    height: 100%;
}

.rbc-calendar .rbc-row {
    height: 100%;
    max-height: 40px;
}

html,
body {
    min-height: 100vh;
    line-height: 1;
    text-rendering: optimizeSpeed;
  }


#root {
    height: 100vh;
    min-height: 100vh;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

*, *::before,*::after {
    box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  border: 0 solid transparent;
}

